import { Controller } from "stimulus"

import Vue from 'vue/dist/vue.esm'
import ToggleButton from 'vue-js-toggle-button'
import axios from '../mixins/axiosDefaults'

Vue.use(ToggleButton)

export default class extends Controller {
  connect() {
    const el = this.element;
    const togglePath = this.data.get('path');
    const app = new Vue({
      el,
      data: {
        checked: this.data.has('checked'),
        locked: this.data.has('locked'),
        loading: false,
        reload: this.data.has('reload')
      },
      template: '<toggle-button v-model="checked" :disabled="disabled" :labels="true" :sync="true" @change="toggle"></toggle-button>',
      computed: {
        disabled() {
          return this.locked || this.loading;
        }
      },
      methods: {
        toggle(val) {
          this.loading = true;
          axios
            .patch(togglePath, {checked: val.value})
            .catch(this.failed)
            .then(this.success)
            .finally(() => this.loading = false)
        },
        success(response){
          this.checked = response.data.checked; // Ensure matches
          if (this.reload) document.location.reload(true);
        },
        failed(error) {
          this.checked = !this.checked;
          if (error.response.status === 401) {
            location.reload() // Not authenticated. Reload page
          } else {
            alert('Please try again. If you continue to see this error contact us and we\'ll get it worked out.')
          }
        }
      }
    });
  }
}
