import { Controller } from "stimulus"
import { EventBus } from '../mixins/events.js'

export default class extends Controller {
  static targets = [ 'settings' ]

  connect() {
    const uploader = $('#uploader');

    uploader.S3Uploader({
      drop_zone: $('#dropzone'),
      progress_bar_target: $('#upload_progress'),
      remove_completed_progress_bar: true
    })

    uploader.bind("s3_uploads_start", (e) => {
      EventBus.$emit('uploadStarted', e)
    });

    uploader.bind("s3_uploads_complete", (e, data) => {
      EventBus.$emit('uploadCompleted', e, data)
    });

    uploader.bind('s3_upload_failed', (e, data) => {
      EventBus.$emit('uploadFailed', e, data)
    });

    uploader.bind('ajax:success', (e, data) => {
      EventBus.$emit('uploadSuccess', e, data)
    });
  }
}
