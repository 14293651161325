import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../mixins/events.js'

export default class extends Controller {
  static values = { siblings: Boolean }

  connect() {
    const pathname = this.element.pathname;

    const a = this.element.cloneNode(true);
    a.removeAttribute('data-controller'); // Otherwise will cause a loop
    a.setAttribute('v-on:click.prevent', 'openResponse');

    const vm = new Vue({
      el: this.element,
      template: a.outerHTML,
      methods: {
        openResponse: () => {
          EventBus.$emit('openResponse', pathname, this.siblingsValue)
        }
      }
    })
  }
}
