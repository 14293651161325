import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../mixins/events.js'

export default class extends Controller {
  static values = { siblings: Boolean }

  connect() {
    const a = document.createElement('a');
    const pathname = this.element.pathname;

    a.href = pathname;
    a.className = this.element.className;
    a.setAttribute('v-on:click.prevent', 'openForm')
    a.innerHTML = this.element.innerHTML
    a.title = this.element.title

    const vm = new Vue({
      el: this.element,
      template: a.outerHTML,
      methods: {
        openForm: () => {
          console.log(this.siblingsValue)
          EventBus.$emit('openForm', pathname, this.siblingsValue)
        }
      }
    })
  }
}
