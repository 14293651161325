import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).tooltip({
      selector: '.attachments .fal, .icon-flag, .icon-flagged, .forms .fal',
      html: true
    })
  }
}
