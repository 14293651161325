<template>
  <div>
    <alert type="danger" v-if="error">
      <i class="fas fa-exclamation-triangle fa-lg" /> <span v-html="errorMessage" />
    </alert>

    <div class="empty_message" v-if="loading">Loading...</div>
    <div class="empty_message" v-else-if="messages.length === 0">No response activity. As bidders complete tasks and upload files, they will appear at the top of this list.</div>

    <table class="table clickable-rows">
      <tbody>
        <tr v-for="(message, index) in messages" @click="toggleOpen(index)">
          <td class="nowrap" width=100>{{message.date}}</td>
          <td width=36 class="nowrap">
            <span style="background: #f5f5f5; border-radius: 8px; padding: 0px 6px; color: #666" v-if="message.attachmentCount > 0">
              <i class="fal fa-paperclip"></i> {{message.attachmentCount}}
            </span>
          </td>
          <td width=36 style="text-align: right" class="nowrap">
            <i class="fas fa-flag-alt fa-fw icon-flagged" v-if="message.flagged"></i>
            <span :class="{'badge': true, 'badge-success': (message.remainingTasks == 0), 'badge-warning': (message.remainingTasks > 0), 'badge-number': true}"><span class="name">{{message.remainingTasks}}</span></span>
          </td>
          <td>
            <div class="truncate-ellipsis">
              <a :href="message.responsePath" @click.prevent>
                {{message.memberName}}
                <tooltip
                  text="Suspended Bidder"
                  custom-class="tooltip-suspended"
                  v-if="message.suspended"
                >
                  <i class="fas fa-user-slash" style="color: Tomato" />
                </tooltip>
                <small>{{message.respondableName}}</small>
              </a>
            </div>
          </td>
          <td>
            <div class="truncate-ellipsis">
              <span>{{message.documentName}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <modal v-model="open" class="inbox" :footer="false" v-if="open">
      <template slot="header">
        <div class="modal-loading" v-if="childLoadedCount < 2">
          <span>Loading&hellip;</span>
        </div>
        <div class="pull-right">
          <div class="btn-group">
            <btn size="lg" @click="previous" :disabled="!hasPrevious"><i class="fa fa-arrow-left"></i></btn>
            <btn size="lg" @click="next" :disabled="!hasNext"><i class="fa fa-arrow-right"></i></btn>
          </div>
          <btn size="lg" @click="open=false"><i class="fa fa-times"></i><span class="hidden-xs"> Close</span></btn>
        </div>
        <div class="pull-right" style="padding: 14px 14px 0 0; font-size: .9em; color: #333;">{{index + 1}} of {{messages.length}}</div>
        <a :href="response.documentPath" class="btn btn-lg btn-nav hidden-xs"><i class="fas fa-pencil"></i> Document</a>
        <a :href="response.responsePath" class="btn btn-lg btn-nav"><i class="fas fa-envelope-open-text"></i> Response</a>
      </template>

      <div class="row">
        <div class="col-md-12">
          <div class="action_column">
            <section class="action_header">
              <header>
                <div class="subtitle pull-right">{{response.date}}</div>
                <h2>
                  {{response.memberName}}
                  <small>{{response.respondableName}}</small>
                  <tooltip
                    text="Suspended Bidder"
                    v-if="response.suspended"
                    custom-class="tooltip-suspended"
                  >
                    <i class="fas fa-user-slash" style="color: Tomato" />
                  </tooltip>
                </h2>
                <div class="subtitle">{{response.documentName}} Response</div>
              </header>
            </section>
            <upload-list
              :url="uploadsPath"
              :downloadPath="response.downloadPath"
              :key="uploadsPath"
              :sealed="response.sealed"
              :peek="response.peek"
              @loaded="childLoaded"
            />
            <task-list
              :read-only="true"
              :member-id="response.respondableId"
              :base-url="response.documentPath"
              :key="response.responsePath"
              @loaded="childLoaded"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from '../mixins/axiosDefaults'
  import { EventBus } from '../mixins/events.js'
  import { inbox } from '../mixins/inbox'
  import dataFetcher from '../mixins/dataFetcher'
  import TaskList from './TaskList.vue'
  import UploadList from './UploadList.vue'
  import MessageViewer from './MessageViewer'
  import { Alert, Btn, Modal, Tooltip } from 'uiv'

  export default {
    components: { TaskList, UploadList, MessageViewer, Alert, Btn, Modal, Tooltip },
    mixins: [inbox, dataFetcher],
    data: function () {
      return {
        response: {},
        loaded: false,
        childLoadedCount: 0
      }
    },
    computed: {
      uploadsPath() {
        return this.response.responsePath + '/attachments'
      }
    },
    methods: {
      loadData(response) {
        this.messages = response.data;
        this.loaded = true;
      },
      toggleOpen(index) {
        this.childLoadedCount = 0;
        this.index = index;
        this.response = this.messages[index];
        this.open = true;
      },
      childLoaded() {
        this.childLoadedCount++
      }
    }
  }
</script>

<style lang="scss">

  .modal-body header h2 small{
    color: #f4e1c1;
    font-size: .75em;
    font-weight: normal;
  }
  .inbox .modal-body{
    padding: 10px;
    background: #f5f5f5;
  }
  .clickable-rows tr:hover td {
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }

</style>
