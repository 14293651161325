import { Controller } from "stimulus"
import TomSelect from "tom-select";

const REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@' +
                '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

export default class extends Controller {
  static targets = ['searchField', 'warning', 'destinationOrgName', 'orgId']

  static values = {
    maxItems: Number,
    org: Number
  }

  connect() {
    this.enableTS();
    $(this.element).tooltip({
      container: this.element,
      selector: 'div.not-found, div.existing'
    })

    if (this.hasOrgIdTarget) {
      // Select2 uses jquery events
      $(this.orgIdTarget).on('change', (e) => {
        this.element.setAttribute('data-user-search-org-value', this.orgIdTarget.value)
      })
    }
  }

  orgValueChanged() {
    const select = this.searchFieldTarget.tomselect;
    if (select) {
      select.clearOptions();
      select.load('');
    }
  }

  enableTS() {
    let emails = [];
    if (this.data.has('emails')) {
      emails = JSON.parse(this.data.get('emails'));
    }

    // maxItems needs to be null, not 0
    const maxItems = (this.maxItemsValue > 0) ? this.maxItemsValue : null;
    const openOnFocus = this.maxItemsValue === 1 || this.hasOrgValue;
    const plugins = (this.maxItemsValue === 1) ? ['dropdown_input'] : ['remove_button'];

    const select = new TomSelect(this.searchFieldTarget,
      {
        plugins: plugins,
        maxItems: maxItems,
        preload: this.hasOrgValue,
        splitOn: /\s*[,;\s]+\s*/,
        valueField: 'email',
        labelField: 'name',
        openOnFocus: openOnFocus,
        closeAfterSelect: true,
        searchField: ['name', 'organizationName', 'email'],
        sortField: [
          {field: 'name', direction: 'asc'},
          {field: 'email', direction: 'asc'},
          {field: 'organizationName', direction: 'asc'}
        ],
        onInitialize: function() {
          emails.forEach(e => this.createItem(e))
        },
        load: (term, callback) => {
          if (term === undefined) term = '';
          const url = this.data.get('url') + '?' + new URLSearchParams({term: term, organization_id: this.orgValue})
          fetch(url)
            .then(response => response.json())
            .then(json => {
              callback(json);
            })

        },
        render: {
          item: (item, escape) => {
            if (maxItems === 1) {
              this.warningTarget.classList.toggle('hidden', item.organizationId === this.orgValue);
              this.destinationOrgNameTarget.innerText = item.organizationName;
            }
            const klass = item.notFound ? 'not-found' : item.isMember ? 'existing' : '';
            const title = item.notFound ? 'User Not Found' : item.isMember ? 'Already a member' : '';
            const content = '<div class="' + klass + '" title="' + title + '">' +
              (item.name ? escape(item.name) : '') +
              (item.email ? '<span class="email">' + escape('<' + item.email + '>') + '</span>' : '') +
            '</div>';

            return content
          },
          option: function(item, escape) {
            var label = escape(item.name) + '<span class="email">' + escape('<' + item.email + '>') + '</span>';
            return '<div>' +
              '<span class="label">' + label + '</span>' +
              '<span class="caption">' + escape(item.organizationName) + '</span>' +
            '</div>';
          }
        },
        createFilter: function(input) {
          var regexpA = new RegExp('^' + REGEX_EMAIL + '$', 'i');
          var regexpB = new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i');
          return regexpA.test(input) || regexpB.test(input);
        },
        create: async (input, callback) => {
          console.log('create', input)
          const url = this.data.get('url') + '?email=' + encodeURIComponent(input);
          let user = await fetch(url)
            .then(response => response.json())
            .then(json => json[0])
          if (user === undefined) user = this.parseEmail(input);
          console.log('create user', user)
          return await callback(user)
        }
      });

    // A hack to remove persisted tooltip on delete
    select.on('item_remove', () => {
      $('.tooltip', this.element).remove()
    });
  }

  parseEmail(input) {
    if ((new RegExp('^' + REGEX_EMAIL + '$', 'i')).test(input)) {
      return {email: input, notFound: true};
    }
    const match = input.match(new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i'));
    if (match) {
      const email = match[2],
            name = match[1].trim();

      return {
        email,
        name,
        notFound: true
      };
    }

    console.error('Invalid email address.');
    return false;
  }
}
