import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import AcceptEula from '../components/AcceptEula'

export default class extends Controller {
  connect() {
    const vm = new Vue({
      el: this.element,
      components: {AcceptEula},
      render: h => h(AcceptEula)
    })
  }
}
