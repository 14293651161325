import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import TaskList from '../components/TaskList'

export default class extends Controller {
  connect() {
    let baseUrl = this.data.get('baseUrl');
    let memberId = this.data.get('memberId');

    const vm = new Vue({
      el: this.element,
      render: h => h(TaskList, {props: {memberId: memberId, baseUrl: baseUrl}}),
      components: {
        TaskList
      }
    })
  }
}
