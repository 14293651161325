import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const url = this.data.get('url');
    console.log('org search', this.element, url)
    $(this.element).select2({
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        results: function (data, page) {
          return {results: data};
        },
        processResults: function (data, params) {
          // parse the results into the format expected by Select2
          // since we are using custom formatting functions we do not need to
          // alter the remote JSON data, except to indicate that infinite
          // scrolling can be used
          return {
            // results: [{id: 1, text: 'Blah Blah'}]
            results: _.map(data, function(org){
              return {id: org.id, text: org.name};
            })
          }
        }
      }
    });
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
}
