<template>
  <div>
    <modal v-model="open" :backdrop="false" :header="false" :keyboard="false" :dismiss-btn="false" size="lg">
       <div class="description document-body" style="padding: 20px">
          <h2 style="text-align: center;">END-USER LICENSE AGREEMENT</h2>
          <div v-html="agreement" />
       </div>

       <span slot="footer">
        <label class="pull-left" style="font-size: 1.1em">
          <input type="checkbox" v-model="agreed" style="width: 16px; height: 16px; margin-right: 10px;"> I Agree to the terms outlined above
          </label>
          <tooltip text="Click the Checkbox to Agree" :enable="!agreed" placement='left'>
            <span>
              <btn @click="accept" type="primary" size="lg" :disabled="!agreed">I Agree</btn>
            </span>
          </tooltip>
       </span>
    </modal>
  </div>
</template>

<script>
  import axios from '../mixins/axiosDefaults'
  import { Btn, Modal, Tooltip } from 'uiv'

  export default {
    components: { Btn, Modal, Tooltip },
    data: function () {
      return {
        open: true,
        agreement: '',
        version: 0,
        agreed: false
      }
    },
    created() {
      this.loadEula();
    },
    methods: {
      loadEula() {
        axios.get('/eula').then(response => {
          const { data } = response;
          this.version = data.version;
          this.agreement = data.agreement
        })
      },
      accept() {
        axios.post('/accept_eula', {version: this.version})
        .then(() => {
          this.open = false;
        })
        .catch(() => {
          alert('Something went wrong.')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
